/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import type { Place } from "./data-contracts";
import type { RequestParams } from "./http-client";
import { ContentType, HttpClient } from "./http-client";
import { NotificationMessages } from './constants';
import { useNotification } from "@kyvg/vue3-notification";
import type { AxiosError } from "axios";
import { useMapStore } from "./stores/map";

export class PlacesApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Place Resource
   * @name CreateCreate
   * @request POST:/place/create
   */
  notification;

  constructor() {
    super({ baseURL: import.meta.env.VITE_BACKEND_URL });
    this.notification = useNotification();

  }
  createCreate = (data: Place, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/place/create`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    }).then((response)=>{
      const store = useMapStore();
      const placeSavedClient = {
        ...NotificationMessages.placeSavedClient,
        title: store.i18n.t(NotificationMessages.placeSavedClient.title),
        text: store.i18n.t(NotificationMessages.placeSavedClient.text)
      }
      this.notification.notify(placeSavedClient);
    });
  /**
   * No description
   *
   * @tags Place Resource
   * @name GetActiveList
   * @request GET:/place/getActive
   */
  getActiveList = (params: RequestParams = {}) =>
    this.request<Place[], any>({
      path: `/place/getActive`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Place Resource
   * @name GetAllList
   * @request GET:/place/getAll
   * @secure
   */
  getAllList = (params: RequestParams = {}) =>
    this.request<Place[], void>({
      path: `/place/getAll`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Place Resource
   * @name UpdateUpdate
   * @request PUT:/place/update
   * @secure
   */
  updatePlace = (data: Place, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/place/update/${data.id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    }).then((response)=>{
      const store = useMapStore();
      const placeSavedAdmin = {
        ...NotificationMessages.placeSavedAdmin,
        title: store.i18n.t(NotificationMessages.placeSavedAdmin.title),
        text: store.i18n.t(NotificationMessages.placeSavedAdmin.text)
      }

      this.notification.notify(placeSavedAdmin);
    });

  login = (data: { j_username: string, j_password: string }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/j_security_check`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      ...params,
    }).catch((error: AxiosError)=>{
      const store = useMapStore();
      const credentialsError = {
        ...NotificationMessages.credentialsError,
        title: store.i18n.t(NotificationMessages.credentialsError.title),
        text: store.i18n.t(NotificationMessages.credentialsError.text)
      }

      this.notification.notify(credentialsError);
    });


}
export default new PlacesApi();

