/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum Category {
  TRIP = "TRIP",
  CHURCH = "CHURCH",
  HOUSE = "HOUSE",
  MEMORIES = "MEMORIES",
  TITUS_ZEMAN = "TITUS_ZEMAN"
}

export interface Coordinates {
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
}

/**
 * @format date
 * @example "2022-03-10T00:00:00.000Z"
 */
export type Date = string;

export type FileUpload = object;

export interface Place {
  id?: string;
  name?: string;
  coordinates: Coordinates;
  description?: string;
  contactMail: string;
  category: Category;
  imageLinks?: string[];
  status: Status;
}

export enum Status {
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  WAITING = "WAITING",
}
