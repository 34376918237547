<script lang="ts">
import { useMapStore } from "./stores/map";

export default {
  setup() {
    const mapStore = useMapStore();
    return { mapStore };
  },
};
</script>

<template>
  <RouterView />
  <notifications classes="maps-notification" />
</template>

<style lang="scss">
.maps-notification {
  @apply rounded-md p-3 m-2;

  &.success {
    @apply bg-white text-secondary border-secondary border;
  }

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    @apply bg-white text-primary border-primary border;
  }
}
</style>
