import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import BalmUI from 'balm-ui';
import 'balm-ui-css';
import './index.css'
import * as Sentry from "@sentry/vue";
import App from './App.vue'
import router from './router'
import './assets/main.css'
import Notifications from '@kyvg/vue3-notification'
import VueGtag from 'vue-gtag'
import { register } from 'swiper/element/bundle';
import { createI18n } from 'vue-i18n';
import sk from './locales/sk.json';

register(); // Register swiper lib

// Create an i18n instance
const i18n = createI18n({
  locale: 'sk', // Set the initial locale
  fallbackLocale: 'sk', // Set the fallback locale
  legacy: false,
  messages: {
    sk: sk
  },
});

const app = createApp(App)  

if(import.meta.env.VITE_LOAD_SENTRY === 'true'){
   
    Sentry.init({
        app,
        dsn: "https://834c3cad987061a45677391aab764b74@o4506240210042880.ingest.sentry.io/4506240212336640",
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^http:\/\/130.61.151.116\/api/, /^https:\/\/mapa100.saleziani.sk\/api/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
}


const pinia = createPinia();
pinia.use(({ store }) => {
  store.$i18n = i18n;
});
app.use(pinia)
app.use(router)
app.use(
    VueGtag,
    {
        appName: 'sdb-map-markers',
        pageTrackerScreenviewEnabled: true,
        config: { id: 'G-KVN65MF7RC' }
    },
    router
)
app.use(Notifications)
app.use(BalmUI);
// Use the i18n instance in your app
app.use(i18n);

app.mount('#app')
