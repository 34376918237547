const showNotificationFor = 10000;
export const NotificationMessages = {
  placeSavedClient: {
    type: "success",
    title: "notificationMessages.placeSavedClient.title",
    text: "notificationMessages.placeSavedClient.text",
    duration: showNotificationFor,
  },
  placeSavedAdmin: {
    type: "success",
    title: "notificationMessages.placeSavedAdmin.title",
    text: "notificationMessages.placeSavedAdmin.text",
    duration: showNotificationFor,
  },
  errorWithServer: {
    type: "error",
    title: "notificationMessages.errorWithServer.title",
    text: "notificationMessages.errorWithServer.text",
    duration: showNotificationFor,
  },
  credentialsError: {
    type: "error",
    title: "notificationMessages.credentialsError.title",
    text: "notificationMessages.credentialsError.text",
    duration: showNotificationFor,
  }
};

export const ErrorsTypes = {
  tooLarge: {
    name: 'Too large',
    code: 413
  },
  serverError: {
    name: 'Server error',
    code: 500
  }
}