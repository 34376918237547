import { defineStore } from 'pinia'
import PlacesApi from '@/PlaceApi';
import { Status, Category, type Place } from '@/data-contracts';
import router from '@/router';
import type { Composer } from 'vue-i18n';

export interface Filters {
  id: number;
  categoryName: string;
  category: Category | Status;
  iconComponentName: string;
  color: string;
  active: boolean;
};

  export const useMapStore = defineStore('map', {
  state: () => ({
    activeFilters: [
      {
        id: 0,
        categoryName: 'categories.church',
        category: Category.CHURCH,
        iconComponentName: "ChurchIcon",
        color: "secondary",
        active: true,
      },
      {
        id: 1,
        categoryName: 'categories.house',
        category: Category.HOUSE,
        iconComponentName: "HouseIcon",
        color: "primary",
        active: true,
      },
      {
        id: 2,
        categoryName: 'categories.trip' ,
        category: Category.TRIP,
        iconComponentName: "HikingIcon",
        color: "green",
        active: true,
      },
      {
        id: 3,
        categoryName: 'categories.memories',
        category: Category.MEMORIES,
        iconComponentName: "MemoriesIcon",
        color: "accent",
        active: true,
      },
      {
        id: 4,
        categoryName: 'categories.titusZeman',
        category: Category.TITUS_ZEMAN,
        iconComponentName: "BarbWireIcon",
        color: "burgundy",
        active: true,
      }
    ],
    adminFilters: [
      {
        id: 0,
        categoryName: "adminFilters.waiting",
        category: Status.WAITING,
        iconComponentName: "EyeOffIcon",
        color: "warning",
        active: true,
      },
      {
        id: 1,
        categoryName: "adminFilters.approved",
        category: Status.APPROVED,
        iconComponentName: "CheckIcon",
        color: "green",
        active: true,
      },
      {
        id: 2,
        categoryName: "adminFilters.declined",
        category: Status.DECLINED,
        iconComponentName: "CloseIcon",
        color: "gray",
        active: true,
      },
    ] as Filters[],
    activeMapPoints: [] as Place[],
    allMapPoints: [] as Place[],
    selectedPlaceId: '',
    zommedCoordinatesOfMainMap: {
      lat: 48.822927104269155,
      lng: 19.808342001745554,
    },
    zoomOfMainMap: 8
  }),
  getters: {
    getZommedCoordinates(state): {lat: number, lng: number} {
      return state.zommedCoordinatesOfMainMap;
    },
    getZoom(state): number {
      return state.zoomOfMainMap;
    },
    getActiveFilters(state): Filters[] {
      return state.activeFilters;
    },
    getAdminFilters(state): Filters[] {
      return state.adminFilters;
    },
    getSelectedFromActivePlace(state): Place | undefined {
      if(!state.selectedPlaceId){
        return {} as Place;
      }
      return state.activeMapPoints.find((place) => state.selectedPlaceId === place.id);
    },
    getSelectedFromAllPlace(state): Place | undefined {
      if(!state.selectedPlaceId){
        return {} as Place;
      }
      return state.allMapPoints.find((place) => state.selectedPlaceId === place.id);
    },
    getActiveMapPoints(state): Place[] {
      return state.activeMapPoints;
    },
    getAllMapPoints(state): Place[] {
      return state.allMapPoints;
    },
    i18n(): Composer {
      // @ts-ignore
      return this.$i18n.global as Composer;
    },
  },
  actions: {
    activateFilter(id: number, active: boolean) {
      const foundFilter = this.activeFilters.find((item) => id === item.id);

      if (foundFilter) {
        foundFilter.active = active;
        this.activeFilters[foundFilter.id] = foundFilter;
      }

    },
    activateAdminFilter(id: number, active: boolean) {
      const foundFilter = this.adminFilters.find((item) => id === item.id);

      if (foundFilter) {
        foundFilter.active = active;
        this.adminFilters[foundFilter.id] = foundFilter;
      }
    },
    getActivePlaces() {
      PlacesApi.getActiveList().then((response) => {
        this.activeMapPoints = response.data;
      });
    },
    getAllPlaces() {
      PlacesApi.getAllList().then((response) => {
        this.allMapPoints = response.data;
      });
    },
    setSelectedPlace(id: string) {
        this.selectedPlaceId = id;
    },
    setZommedCoordinatesAndZoom(lat: number | undefined, lng: number| undefined, zoom: number | undefined){
      if(lat && lng){
        this.zommedCoordinatesOfMainMap = {lat, lng};
      }
      if(zoom) {
        this.zoomOfMainMap = zoom;
      }
    }
  },

})
